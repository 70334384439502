export function baseUrl() {
  return "https://local.chestermart.com/";
  // return "http://localhost:8080/";
  // return "http://172.20.10.2:8080/";
  // return "https://1661e19423b1f74449656b7317c71587.serveo.net/";
}

export function botURL() {
  return "https://t.me/spycechain_bot";
}
export function ProductUrl() {
  return baseUrl() + "/images/products/";
}
