import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { adminApi } from "./services/admin";
import { usersApi } from "./services/users";
import alert_slice from "./slices/alert_slice";
import claim_token_btn from "./slices/claim_token_btn";
import counter_slice from "./slices/counter_slice";
import fast_fetch_slice from "./slices/fast_fetch_slice";
import voice_call_slice from "./slices/voice_call_slice";



export const store = configureStore({
  reducer: {


    // Slice sections
     // Slice
     [alert_slice.name]: alert_slice.reducer,
     [claim_token_btn.name]: claim_token_btn.reducer,
     [counter_slice.name]: counter_slice.reducer,
     [fast_fetch_slice.name]: fast_fetch_slice.reducer,



     // Api sections
     [usersApi.reducerPath]: usersApi.reducer,
     [adminApi.reducerPath]: adminApi.reducer,
 
    
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false, // @ serializableCheck:false; This help you to work with input form
    })
    .concat(usersApi.middleware),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch