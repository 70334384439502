import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  counter: 0,
  maxCount: 0
};

const counter_slice = createSlice({
  name: "counter_slice",
  initialState,
  reducers: {
    SetCountAction: (state, { payload }) => {
      state.counter = payload;
    },
    SetMaxCountAction: (state, { payload }) => {
      state.maxCount = payload;
    },

  },
});

// Action creators are generated for each case reducer function
export const { SetCountAction, SetMaxCountAction } = counter_slice.actions;

export default counter_slice;
