import { useEffect, useState } from "react";
import JustWrapper from "../UI/Wrapper/JustWrapper";
import classes from "../../Styles/Boost.module.css";
import classes_home from "../ClaimHourlyReward/ClaimHourlyReward.module.css";
import BoostList from "../Boost/BoostList";
import { IoIosFlash, IoMdClose } from "react-icons/io";
import { RiSuperscript } from "react-icons/ri";
import { FaRegHandPeace } from "react-icons/fa";
import {
  useGetRequestQuery,
  usePostRequestMutation,
} from "../../store/services/users";
import Spinner from "../UI/Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { ToggleIsOpen } from "../../store/slices/alert_slice";
import useBackButtonHook from "../custom/useBackButtonHook";
import { useUserLevel } from "../custom/useUserLevel";
import { RiRobot2Fill } from "react-icons/ri";
import { GiRollingEnergy } from "react-icons/gi";

function Boost() {
  const { data: user, refetch } = useGetRequestQuery("/user");
  const [postRequest, { isLoading }] = usePostRequestMutation();
  const dispatch = useDispatch();
  const {} = useBackButtonHook();
  const { level } = useUserLevel(user?.balance!);
  let boostArray = [
    {
      id: 2,
      title: "Boost Tap",
      amount: 10000 * user?.boost_tap!,
      icon: <FaRegHandPeace color="gold" size={25} />,
      level: user?.boost_tap!,
      description:
        "Boost your earnings by doubling your taps when you activate Boost Tap for our crypto airdrop! Don’t miss out on this chance to maximize your rewards!",
      type: "boost_tap",
    },
    {
      id: 1,
      title: "Energy Caps",
      amount: 15000 * user?.energy_caps!,
      icon: <GiRollingEnergy color="gold" size={25} />,
      level: user?.energy_caps!,
      description:
        "Enhance your energy levels and watch your rewards grow. Don’t miss out on this opportunity—tap into your earnings today!",
      type: "energy_caps",
    },
    {
      id: 2,
      title: "Super Airdrop",
      amount: 5000 * user?.super_airdrop!,
      icon: <RiSuperscript color="#ca4bd3" size={25} />,
      level: user?.super_airdrop!,
      description:
        "Boost your support for airdrops to significantly increase your hourly earnings and maximize your rewards. Don't miss out on this opportunity to enhance your earning potential!",
      type: "super_airdrop",
    },

    {
      id: 3,
      title: "Recharge Speed",
      amount: 0,
      icon: <IoIosFlash color="gold" size={25} />,
      level: 1,
      description: "",
      type: "recharge_speed",
      isCompleted: true,
    },
    {
      id: 3,
      title: "Tap Bot",
      amount: 5000 * user?.super_airdrop!,
      icon: <RiRobot2Fill color="white" size={25} />,
      level: user?.super_airdrop!,
      description: "",
      type: "tap_bot",
    },
  ];

  const [showReward, setShowReward] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>({});

  const toggleFunction = (item: any) => {
    setShowReward(!showReward);
    setSelectedItem(item);
  };

  // Boost Tap: Increased the counter
  // Energy caps: Increased the max value for a counte

  const onPruchasehandler = () => {
    let body_data = {
      level: selectedItem?.level! + 1,
      amount: selectedItem?.amount!,
      type: selectedItem?.type!,
    };

    postRequest({
      url: "/boost",
      body: body_data,
    })
      .unwrap()
      .then((data) => {
        console.log(data);
        refetch();
        setShowReward(!showReward);
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          ToggleIsOpen({
            toggle: true,
            message: error.data.message,
          })
        );
        setShowReward(!showReward);
      });
  };

  const onFullCapHandler = () => {
    setShowReward(true);
    setSelectedItem({
      title: "RECHARGE BOOST",
      description:
        "The recharge button enables you to completely restore your energy level to 100%. You can use this boost up to three times within a 24-hour period.",
      isFree: true,
    });
  };

  const onRechargeHandler = () => {
    postRequest({
      url: "/full_cap",
      body: {},
    })
      .unwrap()
      .then((data) => {
        console.log(data);
        refetch();
        setShowReward(!showReward);
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          ToggleIsOpen({
            toggle: true,
            message: error.data.message,
          })
        );
        setShowReward(!showReward);
      });
  };

  const [hasTImePassed, setHasTimePassed] = useState<boolean>(false);
  useEffect(() => {
    if (user?.full_cap_time) {
      let is24Hours =
        new Date(user?.full_cap_time!)?.toISOString?.() <
        new Date()?.toISOString?.();

      setHasTimePassed(is24Hours);
    }
  }, [user]);

  return (
    <JustWrapper>
      <div className={classes.wrapper}>
        <h1 className={classes.title_tag}>Boosts</h1>

        <div className={classes.amount_section}>
          <div className={classes.top_}>
            <p>Balance</p>
            <h5> LVL {level}</h5>
          </div>
          <div className={classes.balance_container}>
            <div className={classes.amount_wrapper}>
              <img src="/images/coin_logo.webp" alt="" />
              <h3>{parseFloat(user?.balance).toLocaleString("en")}</h3>
            </div>

            <br />
            <div className={classes.full_cap_wrapper}>
              <button
                onClick={onFullCapHandler}
                disabled={hasTImePassed ? false : true}
              >
                RECHARGE {!hasTImePassed ? "0" : user?.full_cap_count}/3
              </button>
            </div>
          </div>
        </div>
        <h3 className={classes.sub_title}>Mission</h3>
        <div className={classes.boost_wrapper}>

        {boostArray.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                if (
                  item.type === "tap_bot" ||
                  item?.type! === "recharge_speed"
                ) {
                  return;
                }
                if (item.level >= 20) {
                  return;
                }

                if (item.type === "super_airdrop" && item.level >= 5) {
                  return;
                }

                toggleFunction(item);
              }}
            >
              <BoostList item={item} key={index} />
            </div>
          );
        })}
        </div>

      </div>

      {/* Claim button after 1 gr */}
      <>
        {showReward && (
          <div className={classes_home.claim_reward}>
            <div className={classes_home.reward_container}>
              <div></div>
              <button
                className={classes_home.close_reward_modal}
                onClick={() => {
                  setShowReward(false);
                }}
              >
                <IoMdClose />
              </button>
              <div style={{marginBottom: 15}}>
                <h1 className={classes.bold_title}>{selectedItem?.title!}</h1>
                <p className={classes.x_text}>{selectedItem?.description}</p>

                {selectedItem?.isFree! ? (
                  <h5 className={classes.amount_p}>FREE</h5>
                ) : (
                  <h5 className={classes.amount_p}>
                    {parseFloat(selectedItem.amount).toLocaleString("en")} SPC
                  </h5>
                )}
              </div>

              {selectedItem?.isFree! ? (
                <button
                  disabled={isLoading ? true : false}
                  className={classes_home.main_claim_btn}
                  onClick={onRechargeHandler}
                >
                  {isLoading ? <Spinner /> : "CLAIM BOOST"}
                </button>
              ) : (
                <button
                  disabled={isLoading ? true : false}
                  className={classes_home.main_claim_btn}
                  onClick={onPruchasehandler}
                >
                  {isLoading ? <Spinner /> : "PURCHASE"}
                </button>
              )}
            </div>
          </div>
        )}
      </>
    </JustWrapper>
  );
}

export default Boost;
