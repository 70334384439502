import { useEffect, useState } from "react";
import { LEVELS } from "../../Data/level_data";

export const useUserLevel = (points: number) => {

  const [level, setLevel] = useState(1); // Default to level 1

  useEffect(() => {
    const currentLevel = LEVELS.find(
      (lvl) => points >= lvl.minPoints && points <= lvl.maxPoints
    );
    if (currentLevel) {
      setLevel(currentLevel.level);
    }
  }, [points]);

  return { level };
};
