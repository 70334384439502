import { Route, Routes, useLocation } from "react-router-dom";
import { lazy, Suspense } from "react";
import LeaderBoard from "./components/pages/LeaderBoard";
import Tasks from "./components/pages/Tasks";
import Invites from "./components/pages/Invites";
import Boost from "./components/pages/Boost";
import Register from "./components/pages/Register";
import PlayGround from "./components/pages/PlayGround";
import AlertMessage from "./components/AlertMessage/AlertMessage";
import { useSelector } from "react-redux";
import { RootState } from "./store";
import TaskSubmission from "./components/pages/TaskSubmission";
import Spinner from "./components/UI/Spinner/Spinner";
import Page404 from "./components/pages/Page404";
import NoDesktopPage from "./components/pages/NoDesktopPage";
import useIsMobile from "./components/custom/useIsMobile";
import { useFastFetch } from "./components/custom/useFastFetch";

const Home = lazy(() => import("./components/pages/Home"));

const App = () => {
  const location = useLocation();
  // const seelctor  = useSelector((value: RootState) => value.AlertSlice)
  const selector = useSelector((value: RootState) => value.alert_slide);
  const { isDesktop } = useIsMobile();
  const {} = useFastFetch()



  if(isDesktop){
    return <NoDesktopPage />
  }
  return (
    <>
      <Suspense
        fallback={
          <div className={"suspece_styles"}>
            <Spinner />
          </div>
        }
      >
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/leaderboard" element={<LeaderBoard />} />
          <Route path="/tasks" element={<Tasks />} />
          <Route path="/invite" element={<Invites />} />
          <Route path="/boost" element={<Boost />} />
          <Route path="/playground" element={<PlayGround />} />
          <Route path="/submission/:_id" element={<TaskSubmission />} />

          <Route path="/no_desktop" element={<NoDesktopPage />} />
          <Route path="/*" element={<Page404 />} />
        </Routes>
      </Suspense>

      {selector.isOpen && <AlertMessage />}
    </>
  );
};

export default App;
