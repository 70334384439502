import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useGetLeaderBoardsQuery,
  useGetTasksRequestQuery,
} from "../../store/services/users";
import {
  SetInvitesAction,
  SetLeaderBoardAction,
  SetTaskCategoriesAction,
  SetTasksAction,
} from "../../store/slices/fast_fetch_slice";

export const useFastFetch = () => {
  const [limit, setLimit] = useState<number>(10);
  const dispatch = useDispatch();

  const { data: tasks = [], refetch: refetchTasks } = useGetTasksRequestQuery(
    `/tasks?limit=${limit}&&category=tasks`
  );

  const { data: categories = [], refetch: refetchCatgeories } =
    useGetTasksRequestQuery("/categories");

  const {
    data: referrals = [],

    refetch: refetchReferrals,
  } = useGetLeaderBoardsQuery("/referrals");

  const {
    data: leaderboards = [],

    refetch: refetchLeaderBoards,
  } = useGetLeaderBoardsQuery("/leaderboard?limit=100");

  useEffect(() => {
    if (Array.isArray(tasks) && tasks.length > 0) {
      dispatch(SetTasksAction(tasks));
    }
  }, [tasks]);

  useEffect(() => {
    if (Array.isArray(categories) && categories.length > 0) {
      dispatch(SetTaskCategoriesAction(categories));
    }
  }, [categories]);

  useEffect(() => {
    if (Array.isArray(leaderboards) && leaderboards.length > 0) {
      dispatch(SetLeaderBoardAction(leaderboards));
    }
  }, [leaderboards]);

  useEffect(() => {
    if (Array.isArray(referrals) && referrals.length > 0) {
      dispatch(SetInvitesAction(referrals));
    }
  }, [referrals]);

  // Update all states
    // const reFetchData = async () => {
    //   await refetchReferrals();
    //   await refetchCatgeories();
    //   await refetchTasks();
    //   await refetchLeaderBoards();
    // };

    // useEffect(() => {
    //   const intervalId = setInterval(reFetchData, 2000);
    //   return () => clearInterval(intervalId);
    // }, []);

  return {};
};
