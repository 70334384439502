import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetRequestQuery } from "../../store/services/users";
import { WrapperTypes } from "../../types/types";
import useIsMobile from "../custom/useIsMobile";

const Auth: React.FC<WrapperTypes> = ({ children }) => {
  //  Auth here
  const navigate = useNavigate();

  const { isDesktop } = useIsMobile();
  // if (isDesktop) {
  //   navigate("/no_desktop");
  // }

  const token = localStorage.getItem("token");
  const [isExpires, setIsExpired] = useState<boolean>(false);
  const { data: user } = useGetRequestQuery("/user");

  useEffect(() => {
    if (!token || token === "undefined") {
      navigate("/register");

      return;
    }

    const isTokenExpired = (token: any) => {
      const payload = JSON.parse(atob(token.split(".")[1]));
      return Math.floor(Date.now() / 1000) >= payload.exp;
    };

    // Example usage:
    const expired_ = isTokenExpired(token);
    setIsExpired(expired_);
  }, []);

  useEffect(() => {
    if (isExpires) {
      alert("Session has expired");
    }
    if (isExpires || !token || token.length < 1) {
      navigate("/register");
    }
  }, [isExpires]);

  useEffect(() => {
    // setTimeout(() => {
    // if (!user) {
    //   navigate("/register");
    // }
    // }, 1000);
  }, [user]);
  return <>{children}</>;
};

export default Auth;
