import React, { useEffect } from "react";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { ToggleIsOpen } from "../../store/slices/alert_slice";
import classes from "./AlertMessage.module.css";
const AlertMessage: React.FC = ({}) => {
  const dispatch = useDispatch();
  const selector = useSelector((value: RootState) => value.alert_slide);

  const onloseHandler = () => {
    dispatch(
      ToggleIsOpen({
        toggle: false,
        message: "",
        isSuccess: true,
      })
    );
  };
  useEffect(() => {
    setTimeout(() => {
      onloseHandler();
    }, 3000);
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.inner_wrapper} onClick={onloseHandler}>
        <p
          style={{
            color: selector.isSuccess ? "#28a745" : "red",
            width: "100%",
          }}
        >
          {selector.message} Hello world how are yu
        </p>
      </div>
    </div>
  );
};

export default AlertMessage;
