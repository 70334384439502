import React, { useState, useEffect, useRef } from "react";

const PlayGround = () => {
  let start_time = new Date("2024-09-29T18:33:53.869Z");
  let end_time = new Date("2024-09-29T18:50:33.869Z");

  const [currentDate, setCurrentDate] = useState<Date>(start_time);
  const [difference, setDifference] = useState<string | number>(0);

  let tapCount = 4 * 1000;

  useEffect(() => {
    let differenceInMilliseconds = end_time.getTime() - currentDate.getTime();

    console.log("XXX", start_time)

    // Convert milliseconds to seconds
    let differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);

    // Output the result
    console.log(`The difference is ${differenceInSeconds} seconds.`);
    setDifference(differenceInSeconds);
  }, [currentDate]);

  const handleClick = () => {
    const newDate = new Date(currentDate.getTime() + tapCount); // Add 1000 milliseconds (1 second)

    setCurrentDate(newDate);
  };

  useEffect(() => {
    if (difference >= 1000) return;
    let intervalID = setInterval(() => {
      // setCurrentDate((prevState) => new Date(prevState.getTime() - 1000));
      console.log(new Date(currentDate).getTime());
    }, 1000);

    return () => clearInterval(intervalID);
  }, [difference]);



  return (
    <div>
      <h2>Counter: {difference}/1000</h2>
      {/* <h2>End Time: {Math.floor((currentEndTime.getTime() - Date.now()) / 1000)} seconds left</h2> */}
      <button onClick={handleClick}>Decrement End Time by 1s</button>
    </div>
  );
};

export default PlayGround;
