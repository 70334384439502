import React, { useEffect, useState } from "react";
import classes from "./BoostList.module.css";
import { BoostListTypes } from "../../types/types";
import { FaCheckCircle } from "react-icons/fa";
import { GoChevronRight } from "react-icons/go";

const BoostList: React.FC<BoostListTypes> = ({ item }) => {
const [isDisabled, setIsDisabled ] =  useState<boolean>(false)

  useEffect(() => {
    if(item?.type === "tap_bot"){
      setIsDisabled(true)
    }
    if(item?.level >= 20){
      setIsDisabled(true)
    }
    if(item?.isCompleted === true){
      setIsDisabled(true)
    }

    if(item?.type === "super_airdrop" && item?.level >= 5){
      setIsDisabled(true)
    }
  }, [item])

  return (
    <div className={classes.list} style={{
      opacity: isDisabled ? .7: 1
    }}>
      <div className={classes.row_}>
        <div className={classes.icon_div}>
          <i>{item.icon}</i>
        </div>

        <div>
          <p className={classes.xx_text}>{item.title}</p>

          {item?.type! === "tap_bot" ? (
            <p className={classes.coin_value}>Free</p>
          ) : (

            <>
            {item?.isCompleted! ?
          
          <p className={classes.coin_value}>
          Max upgraded
        </p>
          :
          
          <p className={classes.coin_value}>
              {parseFloat(item.amount).toLocaleString("en")} SPC - LVL{" "}
              {item.level}
            </p>
            }
            </>
          )}
        </div>
      </div>

      <div className={classes.action_btn}>
      {item?.isCompleted === true &&  <FaCheckCircle color="#72ff72" size={20} />}

        {item?.type! === "tap_bot" || item?.type! === "recharge_speed"  ? null : <GoChevronRight size={20} />}
      </div>
    </div>
  );
};

export default BoostList;
