import React from "react";
import { WrapperTypes } from "../../../types/types";
import Auth from "../../Auth/Auth";
import BottomNavigator from "../BottomNavigator/BottomNavigator";
import classes from "./Wrapper.module.css";
const JustWrapper: React.FC<WrapperTypes> = ({ children }) => {
  return (
    <Auth>
      <div className={classes.body}>{children}</div>
    </Auth>
  );
};

export default JustWrapper;
