import { FiHome } from "react-icons/fi";
import { MdOutlineLeaderboard } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { FaTasks } from "react-icons/fa";

import { Link } from "react-router-dom";
import classes from "./BottomNavigator.module.css";
import { useGetRequestQuery } from "../../../store/services/users";
import { useEffect, useState } from "react";
import { BottomNavigatorTypes } from "../../../types/types";
const BottomNavigator: React.FC<BottomNavigatorTypes> = ({hasBg}) =>  {
  const {
    data: tasks = [],
    isLoading,
    refetch,
  } = useGetRequestQuery(`/tasks?limit=${100}&&category=tasks`);

  const [taskBadge, setTaskBadge] = useState<boolean>(false);

  useEffect(() => {
    let filteredTasks = tasks?.filter?.(
      (item: any) => item.isCompleted === false
    );

    setTaskBadge(filteredTasks.length > 0 ? true : false);
  }, [tasks]);

  let listArr = [
    {
      name: "Home",
      icon: <FiHome />,

      path: "/",
    },

    {
      name: "Earn",
      icon: <FaTasks />,
      path: "/tasks",
    },
    {
      name: "Invites",
      icon: <FiUsers />,
      path: "/invite",
    },

    {
      name: "Leaderboard",
      icon: <MdOutlineLeaderboard />,
      path: "/leaderboard",
    },
  ];
  let path_name = window.location.pathname;

  return (
    <div className={classes.nav_wrapper} style={{background: hasBg ? "black": "00000032" }}>
      {listArr.map((item, index) => {
        return (
          <Link to={item.path} key={index} className={classes.wrapper_x}>
            <i
              style={{
                color: item.path === path_name ? "#30a8d4" : "#bbb",
                position: "relative",
              }}
            >
              {" "}
              {item.icon}
              {item.name === "Earn" && taskBadge ? (
                <div className={classes.badge}></div>
              ) : null}
            </i>
            <p style={{ color: item.path === path_name ? "#30a8d4" : "white" }}>
              {item.name}
            </p>
          </Link>
        );
      })}
    </div>
  );
}

export default BottomNavigator;
