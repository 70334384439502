import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  message: "",
  isSuccess: true
};

const alert_slice = createSlice({
  name: "alert_slide",
  initialState,
  reducers: {
    ToggleIsOpen: (state, { payload }) => {
      state.isOpen = payload.toggle;
      state.message = payload.message;
      state.isSuccess = payload.isSuccess;
    },
  },
});

// Action creators are generated for each case reducer function
export const { ToggleIsOpen } = alert_slice.actions;

export default alert_slice;
