import React, { useState } from "react";
import classes from "./TaskList.module.css";
import { TaskListTypes } from "../../types/types";
import { BsFacebook, BsTwitterX } from "react-icons/bs";
import { SiYoutubekids } from "react-icons/si";
import { BsInstagram } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import {
  usePostRequestMutation,
  usePostTasksRequestMutation,
} from "../../store/services/users";
import Spinner, { SmallSpinner } from "../UI/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import { ToggleIsOpen } from "../../store/slices/alert_slice";
import { useDispatch } from "react-redux";
import { GoCheckCircle } from "react-icons/go";

const TaskList: React.FC<TaskListTypes> = ({ item }) => {
  const [postRequest, { isLoading }] = usePostTasksRequestMutation();
  const [showCLaim, setShowClaim] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onStartHandler = () => {
    window.open(item?.link!, "_blank");
    setTimeout(() => {
      if (item.is_quiz === "has_quiz") {
        navigate("/submission/" + item?._id);
      } else {
        setShowClaim(true);
      }
    }, 800);
  };

  const onClaimHandler = () => {
    postRequest({
      url: "/task-submission",
      body: {
        task_id: item?._id!,

        // submission: enteredValue,
        is_submission: false,
      },
    })
      .unwrap()
      .then((data) => {
        console.log(data);
        dispatch(
          ToggleIsOpen({
            toggle: true,
            message: data.message,
            isSuccess: true,
          })
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          ToggleIsOpen({
            toggle: true,
            message: error.data.message,
            isSuccess: false,
          })
        );
      });
  };
  return (
    <div className={classes.list}>
      <div className={classes.row_}>
        <div className={classes.col1}>
        <div className={classes.icon_div}>
          <i>
            {item?.platform! === "x" && <BsTwitterX color="white" />}
            {item?.platform! === "youtube" && <SiYoutubekids color="#FF0000" />}
            {item?.platform! === "instagram" && <BsInstagram color="#E4405F" />}
            {item?.platform! === "facebook" && <BsFacebook color="#0A66C2" />}
            {item?.platform! === "telegram" && (
              <FaTelegramPlane color="#1877F2" />
            )}
            {item?.platform! === "linkedin" && <FaLinkedinIn color="#0A66C2" />}
          </i>
        </div>
        </div>

        <div className={classes.col2} onClick={onStartHandler}>
          <p className={classes.xx_text}>{item?.name!}</p>
          <p className={classes.x_text}>{item?.description!}</p>
        </div>
      </div>

      {item?.isCompleted === true ? (
        <div className={classes.action_btn}>
          <p>+{parseFloat(item?.reward_amount!).toLocaleString("en")}SPC</p>
          <GoCheckCircle size={18} color="#30a8d4" />
        </div>
      ) : (
        <div className={classes.action_btn}>
          {showCLaim ? (
            <>
              {isLoading ? (
                <SmallSpinner />
              ) : (
                <button className={classes.claim_btn} onClick={onClaimHandler}>
                  Claim
                </button>
              )}
            </>
          ) : (
            <button onClick={onStartHandler}>Start</button>
          )}
        </div>
      )}
    </div>
  );
};

export default TaskList;
