import React from "react";
import Wrapper from "../UI/Wrapper/Wrapper";
import classes from "../../Styles/LeaderBoard.module.css";
import { FaMedal } from "react-icons/fa";
import { colorData } from "../../Data/Colors";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export function retrieveColor(username: string, color_arr: any[]) {
  let name_img = username.substring(0, 2);
  let first_lette = username[0]?.toLowerCase?.();

  let filteredColor = color_arr.filter(
    (item) => item.value.toLowerCase() == first_lette
  )[0]?.color!;

  return { filteredColor, name_img };
}

export const UserSkeleton = () => {
  return (
    <div className={"skeleton_row"}>
      <div
        className="skeleton"
        style={{ borderRadius: "50%", width: "50px", height: "50px" }}
      />
      <div>
        <div
          className="skeleton line_skeleton"
          style={{ height: "10px", width: "200px", marginTop: "0px" }}
        />

        <div
          className="skeleton line_skeleton"
          style={{ height: "10px", width: "100px", marginTop: "10px" }}
        />
      </div>
    </div>
  );
};

function LeaderBoard() {
  // const { data: leaderBoards = [], isLoading } =
  // useGetLeaderBoardsQuery("/leaderboard");

  const { leaderBoards } = useSelector(
    (value: RootState) => value.fast_fetch_slice
  );


  let total = 1840 + leaderBoards?.length!
  const formattedNumber = new Intl.NumberFormat('en', {
    notation: 'compact',
    compactDisplay: 'short'
  }).format(total); // Converts to '1M


  return (
    <Wrapper>
      <div className={classes.container}>
      <div className={classes.wrapper_with_vh}>

        {/* {leaderBoards?.length < 1 ? */}
        {true ?
       <h4 className={classes.title}>
       Members
     </h4>
      :
      
        
        <h4 className={classes.title}>
          {formattedNumber} member
          {leaderBoards?.length! > 1 ? "s" : ""}
        </h4>
        }

        {leaderBoards.length < 1 && [1, 2, 3].map((item) => <UserSkeleton key={item} />)}
        {/* {!isLoading && leaderBoards.length < 1 ? <p style={{textAlign: "center"}} >No record found</p> : null} */}

        {leaderBoards.map((item: any, index: number) => {
          let { filteredColor, name_img } = retrieveColor(
            item?.username!,
            colorData
          );
          return (
            <div className={classes.list} key={index}>
              <div className={classes.row_}>
                <div
                  className={classes.icon_div}
                  style={{ backgroundColor: filteredColor }}
                >
                  <h4 style={{ textTransform: "uppercase" }}>{name_img}</h4>
                </div>

                <div>
                  <p className={classes.xx_text}>{item?.username!}</p>
                  <p className={classes.coin_value}>
                    {parseFloat(item?.balance!).toLocaleString()} SPC
                  </p>
                </div>
              </div>

              <div>
                {index === 0 && <FaMedal color="#FFD700" />}
                {index === 1 && <FaMedal color="#C0C0C0" />}
                {index === 2 && <FaMedal color="#CD7F32" />}
                {index > 2 && <h4>#{index + 1}</h4>}
              </div>
            </div>
          );
        })}
      </div>
      </div>
    </Wrapper>
  );
}

export default LeaderBoard;
