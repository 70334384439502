import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isTouched: false,
  loadOnces: false
};

const claim_token_btn = createSlice({
  name: "claim_token_btn",
  initialState,
  reducers: {
    ToggleIsTouched: (state, { payload }) => {
      state.isTouched = payload;
    },
    setLoadOnce: (state, { payload }) => {
      state.loadOnces = payload;
    },

  },
});

// Action creators are generated for each case reducer function
export const { ToggleIsTouched, setLoadOnce } = claim_token_btn.actions;

export default claim_token_btn;
