import React, { useEffect } from "react";
import { WrapperTypes } from "../../../types/types";
import Auth from "../../Auth/Auth";
import BottomNavigator from "../BottomNavigator/BottomNavigator";
import classes from "./Wrapper.module.css";

declare global {
  interface Window {
    Telegram?: any;
  }
}


const Wrapper: React.FC<WrapperTypes> = ({ children, hasBg = true }) => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page



    const telegram = window.Telegram?.WebApp;

    if (telegram) {


    window.addEventListener('load', () => {
      telegram?.ready?.();
      telegram?.expand?.();
    });


  }

    

  }, []);

  return (
    <Auth>
      <div className={ hasBg ? classes.body:classes.body_no_bg}>
        {children}

        <BottomNavigator hasBg={hasBg} />
      </div>
    </Auth>
  );
};

export default Wrapper;
