import React from "react";
import classes from "../../Styles/NoDesktopPage.module.css";
function NoDesktopPage() {
  return (
    <div className={classes.container}>
      <div>
        <h1>Enjoy Airdrop on Mobile!!</h1>

        <p>
          The airdrop is perfect for mobile devices—super convenient and easy to
          use! 📱😊
        </p>
      </div>

      <div className={classes.scanner}>
        <img src="/images/spycechan_qr_code.png" alt="" />
        <br />
        <h3>@SpyceChainBot</h3>
      </div>
    </div>
  );
}

export default NoDesktopPage;
