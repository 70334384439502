import React from "react";
import {  useNavigate } from "react-router-dom";
import classes from "../../Styles/404.module.css";
const Page404 = () => {
  const naviaate = useNavigate();
  return (
    <div className={classes.container}>
      <h1>404</h1>
<h3>We apologize, but the page you are seeking cannot be located.</h3>
<h3>Please verify the URL or try searching for the desired content.</h3>

      <button onClick={() => naviaate(-1)}>Go back</button>
    </div>
  );
};

export default Page404;
