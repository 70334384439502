import React from 'react'
import classes from "./Spinner.module.css"

const Spinner = () => {
  return (
    <div className={classes.dot}>
      
    </div>
  )
}

export function SmallSpinner() {
  return (
    <div className={classes.small}>
      
    </div>
  )
}

export default Spinner