import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
declare global {
   interface Window {
     Telegram?: any;
   }
 }

function useBackButtonHook() {
  const navigation  =  useNavigate()
  
   useEffect(() => {
      // Ensure that the Telegram Web App is initialized
      if (window.Telegram.WebApp) {
        const tg = window.Telegram.WebApp;
  
        // Enable and show the back button
        tg.BackButton.show();
  
        // Handle the back button click event
        tg.BackButton.onClick(() => {
          // You can perform custom behavior here
          console.log("Back button clicked!");

          navigation(-1);
        
        });
  
        // Cleanup when the component unmounts
        return () => {
          tg.BackButton.hide(); // Hide the back button if the component unmounts
        };
      }
    }, []);

  return {}
}

export default useBackButtonHook