import React, { useEffect, useState } from "react";
import Wrapper from "../UI/Wrapper/Wrapper";
import classes from "../../Styles/Invites.module.css";
import { IoIosLink } from "react-icons/io";
import {
  TelegramShareButton,
  FacebookShareCount,
  HatenaShareCount,
  OKShareCount,
  PinterestShareCount,
  RedditShareCount,
  TwitterIcon,
  TwitterShareButton,
  XIcon,
} from "react-share";
import { retrieveColor, UserSkeleton } from "./LeaderBoard";
import { colorData } from "../../Data/Colors";
import {
  useGetLeaderBoardsQuery,
  useGetRequestQuery,
} from "../../store/services/users";
import { botURL } from "../../ImageUrl/ImageUrl";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

function Invites() {
  const { data: user } = useGetRequestQuery("/user");
  // const { data: referrals = [], isLoading } =
  //   useGetLeaderBoardsQuery("/referrals");

  const { referrals } = useSelector(
    (value: RootState) => value.fast_fetch_slice
  );

  let ref_link = botURL() + "?start=" + user?._id!;


  const shareData = {
    title: "Join the Revolution: Claim Your SpyceCoin Airdrop!",
    text: "Join SpyceChan airdrop! Grab your free coins and be part of the growing SpyceChain community.",
    url: ref_link, // Replace with your link
  };

  const handleShare = async () => {
    console.log(ref_link);
    await window.navigator.share(shareData);
  };

  const [displayedText, setDisplayedText] = useState("");
  const fullText =
    "Invite your friends and earn 5,000 SPC rewards by joining the airdrop.!";

  useEffect(() => {
    let currentIndex = 0;

    const typeText = () => {
      if (currentIndex < fullText.length) {
        setDisplayedText((prev) => prev + fullText.charAt(currentIndex));
        currentIndex++;
        setTimeout(typeText, 40);
      }
    };

    typeText(); // Start the typing animation

    return () => {
      currentIndex = 0; // Clean up for any re-renders
    };
  }, []);

  return (
    <Wrapper>
      <div className={classes.container}>
        <div className={classes.task_board}>
          <div className={classes.ref_link_container}>
            <button onClick={handleShare}>
              <div>
                <span>Share Referal link</span>
              </div>
              <div>
                <IoIosLink size={17} color={"white"} />
              </div>{" "}
            </button>
          </div>
          <h1>{displayedText}</h1>

          {/* <div className={classes.user_details}>
            <img
              src="/images/chimp.png"
              alt=""
            />
            <p>Spyce</p>
          </div>
          <div className={classes.date_}>
            <p>Earned {(5000 * referrals.length).toLocaleString("en")} SPC</p>{" "}
          </div> */}
        </div>

        {/* Referral */}

        <div className={classes.bottom_wrapper}>
          <div className={classes.row_between}>
            <h4 className={classes.title}>
              {referrals?.length!} Referral
              {referrals?.length > 1 ? "s" : ""}
            </h4>
            {/* <p>Total: {(5000 * referrals.length).toLocaleString("en")} SPC</p> */}
          </div>

          {referrals?.length! < 1 ? [1, 2, 3].map((item) => <UserSkeleton />) :
          
          <>
           {referrals.map((item: any, index: number) => {
            let { filteredColor, name_img } = retrieveColor(
              item?.username!,
              colorData
            );

            return (
              <div className={classes.list} key={index}>
                <div className={classes.row_}>
                  <div
                    className={classes.icon_div}
                    style={{ backgroundColor: filteredColor }}
                  >
                    <p style={{ textTransform: "uppercase" }}>{name_img}</p>
                  </div>

                  <div>
                    <p className={classes.xx_text}>{item?.username!}</p>
                    <p className={classes.coin_value}>
                      {parseFloat(item?.balance!).toLocaleString("en")} SPC
                    </p>
                  </div>
                </div>

                <div>
                  <b className={classes.bold_}>+5,000 SPC</b>
                </div>
              </div>
            );
          })}
          </>
          }
          {/* {referrals.length < 1 ? (
            <p style={{ textAlign: "left" }}>Use the invite link to refer.</p>
          ) : null} */}

         
        </div>
      </div>
    </Wrapper>
  );
}

export default Invites;
