
// Adjust levels so that 73 million corresponds to level 8
export const LEVELS = [
  { minPoints: 0, maxPoints: 1000000, level: 1 },      // 1M points for level 1
  { minPoints: 1000001, maxPoints: 5000000, level: 2 },  // 1M to 5M points
  { minPoints: 5000001, maxPoints: 15000000, level: 3 }, // 5M to 15M points
  { minPoints: 15000001, maxPoints: 30000000, level: 4 }, // 15M to 30M points
  { minPoints: 30000001, maxPoints: 45000000, level: 5 }, // 30M to 45M points
  { minPoints: 45000001, maxPoints: 60000000, level: 6 }, // 45M to 60M points
  { minPoints: 60000001, maxPoints: 72000000, level: 7 }, // 60M to 72M points
  { minPoints: 72000001, maxPoints: 85000000, level: 8 }, // 72M to 85M points -> 73M falls here
  { minPoints: 85000001, maxPoints: 100000000, level: 9 }, // 85M to 100M points
  { minPoints: 100000001, maxPoints: 120000000, level: 10 }, // 100M to 120M points
  { minPoints: 120000001, maxPoints: 140000000, level: 11 }, // 120M to 140M points
  { minPoints: 140000001, maxPoints: 160000000, level: 12 }, // 140M to 160M points
  { minPoints: 160000001, maxPoints: 180000000, level: 13 }, // 160M to 180M points
  { minPoints: 180000001, maxPoints: 200000000, level: 14 }, // 180M to 200M points
  { minPoints: 200000001, maxPoints: 220000000, level: 15 }, // 200M to 220M points
  { minPoints: 220000001, maxPoints: 240000000, level: 16 }, // 220M to 240M points
  { minPoints: 240000001, maxPoints: 260000000, level: 17 }, // 240M to 260M points
  { minPoints: 260000001, maxPoints: 280000000, level: 18 }, // 260M to 280M points
  { minPoints: 280000001, maxPoints: 300000000, level: 19 }, // 280M to 300M points
  { minPoints: 300000001, maxPoints: Infinity, level: 20 },  // 300M+ points
];
