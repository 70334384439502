import React, { useEffect, useState } from "react";
import Wrapper from "../UI/Wrapper/Wrapper";
import classes from "../../Styles/Tasks.module.css";
import TaskList from "../TaskBoard/TaskList";
import {
  useGetRequestQuery,
  useGetTasksRequestQuery,
  usePostRequestMutation,
} from "../../store/services/users";
import { useInView } from "react-intersection-observer";
import { IoMdCloseCircle } from "react-icons/io";
import { BsSearch } from "react-icons/bs";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export const UserSkeleton = () => {
  return (
    <div
      className={"skeleton_row"}
      style={{ marginTop: 15, marginLeft: 10, marginBottom: 15 }}
    >
      <div
        className="skeleton"
        style={{ borderRadius: "10px", width: "55px", height: "55px" }}
      />
      <div>
        <div
          className="skeleton line_skeleton"
          style={{ height: "14px", width: "200px", marginTop: "0px" }}
        />

        <div
          className="skeleton line_skeleton"
          style={{ height: "14px", width: "100px", marginTop: "10px" }}
        />
      </div>
    </div>
  );
};

function Tasks() {
  let [currentNav, setCurrentNav] = useState<string>("tasks");
  let [enteredValue, setEnteredValue] = useState<string>("");
  const { tasks: fastTasks = [], tasksCategories } = useSelector(
    (value: RootState) => value.fast_fetch_slice
  );

  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  const [limit, setLimit] = useState<number>(10);

  const {
    data: categories = [],
    isLoading: isLoadingCategories,
    isFetching,
  } = useGetTasksRequestQuery("/categories");
  const {
    data: tasks = [],
    isLoading,
    refetch,
  } = useGetTasksRequestQuery(`/tasks?limit=${limit}&&category=${currentNav}`);

  useEffect(() => {
    if (inView) {
      setLimit(limit + 10);
      setTimeout(() => {
        refetch();
      }, 100);
    }
  }, [inView]);
  const onCHangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredValue(e.target.value);
  };

  const [taskIsLoaded, setTaskIsLoaded] = useState<boolean>(false);
  useEffect(() => {
    if (Array.isArray(tasks) && tasks.length > 0) {
      setTimeout(() => {
        setTaskIsLoaded(true);
      }, 500);
    }

    return;
  }, [tasks]);

  // const {
  //   data: searchedItems = [],
  //   isLoading: isLoadingSeatchedItems,
  //   refetch: isRefecthFetchedItems,
  // } = useGetRequestQuery(`/search-tasks?limit=${limit}&&query=${enteredValue}`);

  // useEffect(() => {
  //   let intercalId = setTimeout(() => {
  //     isRefecthFetchedItems();
  //   }, 1000);

  //   return () => clearTimeout(intercalId);
  // }, [enteredValue]);

  const [PostRequest, { isLoading: isLoadingSeatchedItems }] =
    usePostRequestMutation(); // Imported

  const [searchedItems, setFoundItem] = useState([]);

  function searchFuntion() {
    if (enteredValue.length < 1) return;
    PostRequest({
      url: "/search-tasks",
      body: {
        query: enteredValue,
        category: currentNav,
      },
    })
      .unwrap()
      .then((data) => {
        console.log(data);
        setFoundItem(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    let intervalIdid = setTimeout(() => {
      searchFuntion();
    }, 600);

    return () => clearTimeout(intervalIdid);
  }, [enteredValue]);

  return (
    <Wrapper>
      <div className={classes.conatiner}>
        <div className={classes.wrapper}>
          <h1 className={classes.title_tag}>Tasks</h1>
          <div className={classes.search_wrapper}>
            <input
              type="text"
              placeholder="Search"
              onChange={onCHangeHandler}
              value={enteredValue}
            />
            <div className={classes.search_icon}>
              <BsSearch />
            </div>
            {enteredValue.length > 0 && (
              <div className={classes.clear_icon}>
                <IoMdCloseCircle onClick={() => setEnteredValue("")} />
              </div>
            )}
          </div>
          {enteredValue.length < 1 && (
            <div className={classes.loading_nav_tab}>
              {tasksCategories.map((item: any, index: number) => {
                return (
                  <button
                    key={index}
                    onClick={() => {
                      setCurrentNav(item?.name);
                      console.log(item);
                      refetch();
                      // searchFuntion();
                    }}
                    style={{
                      backgroundColor:
                        item?.name === currentNav ? "#4e4e4ed9" : "#4e4e4e",
                      color: item?.name === currentNav ? "white" : "#b0b0b0",
                    }}
                  >
                    {item?.name}
                  </button>
                );
              })}
            </div>
          )}

          <div className={classes.task_wrapper}>
            {enteredValue.length < 1 ? (
              <>
                {taskIsLoaded ? (
                  <>
                    {isLoading || isFetching ? (
                      [1, 2, 3].map((item) => <UserSkeleton key={item} />)
                    ) : (
                      <>
                        {tasks?.map?.((item: any) => {
                          return <TaskList item={item} key={item?.id!} />;
                        })}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {fastTasks?.map?.((item: any) => {
                      return <TaskList item={item} key={item?.id!} />;
                    })}
                  </>
                )}
              </>
            ) : (
              <>
                {isLoadingSeatchedItems || isFetching ? (
                  [1, 2, 3].map((item) => <UserSkeleton key={item} />)
                ) : (
                  <>
                    {searchedItems?.map?.((item: any) => {
                      return <TaskList item={item} key={item?.id!} />;
                    })}
                  </>
                )}
              </>
            )}
          </div>
          {/* {isLoading && <Spinner />} */}

          <div id="posts" ref={ref} style={{ height: "20px" }} />
          {/* <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br /> */}
        </div>
      </div>
    </Wrapper>
  );
}

export default Tasks;
