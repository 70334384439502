import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tasks: [],
  tasksCategories: [],
  leaderBoards: [],
  referrals: [],
};

const fast_fetch_slice = createSlice({
  name: "fast_fetch_slice",
  initialState,
  reducers: {
    SetTasksAction: (state, { payload }) => {
      state.tasks = payload;
    },
    SetLeaderBoardAction: (state, { payload }) => {
      state.leaderBoards = payload;
    },
    SetTaskCategoriesAction: (state, { payload }) => {
      state.tasksCategories = payload;
    },
    SetInvitesAction: (state, { payload }) => {
      state.referrals = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { SetTasksAction, SetLeaderBoardAction, SetInvitesAction, SetTaskCategoriesAction } =
  fast_fetch_slice.actions;

export default fast_fetch_slice;
