

export const colorData = [
  { value: "A", color: "#9b2d2f" }, // Darker red
  { value: "B", color: "#a21c58" }, // Deep magenta
  { value: "C", color: "#2a6fdb" }, // Rich blue
  { value: "D", color: "#4a4a4a" }, // Charcoal
  { value: "E", color: "#5b3f8f" }, // Muted purple
  { value: "F", color: "#d1a36e" }, // Warm beige
  { value: "G", color: "#007d6c" }, // Teal
  { value: "H", color: "#2a4d5d" }, // Deep teal
  { value: "I", color: "#c8b94f" }, // Olive green
  { value: "J", color: "#c97c00" }, // Burnt orange
  { value: "K", color: "#8a1b1f" }, // Dark burgundy
  { value: "L", color: "#4a7c92" }, // Slate blue
  { value: "M", color: "#5e2a91" }, // Deep violet
  { value: "N", color: "#2a6fdb" }, // Rich blue (repeated)
  { value: "O", color: "#234e39" }, // Forest green
  { value: "P", color: "#bda709" }, // Olive
  { value: "Q", color: "#861c7e" }, // Dark fuchsia
  { value: "R", color: "#a5004d" }, // Dark pink
  { value: "S", color: "#0095c8" }, // Steel blue
  { value: "T", color: "#005f8a" }, // Dark blue
  { value: "U", color: "#2f8a63" }, // Sage green
  { value: "V", color: "#c55b3a" }, // Muted coral
  { value: "W", color: "#0e2a47" }, // Deep navy
  { value: "X", color: "#0d97b1" }, // Cyan
  { value: "Y", color: "#02b5a1" }, // Seafoam green
  { value: "Z", color: "#a12b2e" }, // Burgundy red
];
