import React, { useEffect } from "react";
import Spinner from "../UI/Spinner/Spinner";
import classes from "../../Styles/Register.module.css";
import { usePostRequestMutation } from "../../store/services/users";
import { useNavigate, useParams } from "react-router-dom";
import useIsMobile from "../custom/useIsMobile";

// Extend the Window interface to include Telegram

declare global {
  interface Window {
    Telegram?: any;
  }
}

// 1333824

function Register() {
  const navigate = useNavigate();

  // const { isDesktop } = useIsMobile();
  const [postRequest] = usePostRequestMutation();



  useEffect(() => {
  

    const telegram = window.Telegram?.WebApp;

    if (telegram) {
      const user = telegram.initDataUnsafe?.user;

      telegram?.expand()

      const userId = user?.id || "a348347834";
      const username = user?.username || "demo";

      postRequest({
        url: "/register",
        body: {
          _id: userId,
          username: username,
        },
      })
        .unwrap()
        .then((data) => {
          // console.log(data.);
          window.localStorage.setItem("token", data.token);
          window.localStorage.setItem("user_Id", userId.toString());
          setTimeout(() => {
            navigate("/");
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <div className={classes.wrapper}>
      <Spinner />
    </div>
  );
}

export default Register;
