import React, { useState } from "react";
import Wrapper from "../UI/Wrapper/Wrapper";
import classes from "../../Styles/TaskSubmission.module.css";
import {
  useGetRequestQuery,
  usePostRequestMutation,
} from "../../store/services/users";
import Spinner from "../UI/Spinner/Spinner";
import { useParams } from "react-router-dom";
import { ToggleIsOpen } from "../../store/slices/alert_slice";
import { useDispatch } from "react-redux";
import useBackButtonHook from "../custom/useBackButtonHook";

const TaskSubmission: React.FC = () => {
  const [enteredValue, setEnteredValue] = useState<string>("");
  const [postRequest, { isLoading }] = usePostRequestMutation();
  const { _id } = useParams();
  const {} = useBackButtonHook();
  const { data } = useGetRequestQuery("/task/" + _id);
  const dispatch = useDispatch();
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredValue(e.target.value);
  };

  const onOpenLinkHandler = () => {
    window.open(data?.link!);
  };

  const onSubmitHandler = () => {
    postRequest({
      url: "/task-submission",
      body: {
        task_id: data?._id!,

        submission: enteredValue,
        is_submission: true,
      },
    })
      .unwrap()
      .then((data) => {
        console.log(data);
        dispatch(
          ToggleIsOpen({
            toggle: true,
            message: data.message,
            isSuccess: true,
          })
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          ToggleIsOpen({
            toggle: true,
            message: error.data.message,
            isSuccess: false,
          })
        );
      });
  };

  return (
    <Wrapper>
      <div className={classes.container}>
        <h1>{data?.name!}</h1>
        <p>{data?.description!}</p>
        <br />
        <hr />
        <br />
        <div>
          <p onClick={onOpenLinkHandler} className={classes.video_watch}>
            Watch the video to earn {data?.reward_amount!} SPC.
          </p>
        </div>

        {data?.isCompleted ? 
         <div className={classes.input_wrapper}>

       <button
         >
          Completed!
         </button>
        </div>
        : 
         <div className={classes.input_wrapper}>
         <input
           type="text"
           onChange={onChangeHandler}
           placeholder="Enter submission"
         />
         <button
           onClick={onSubmitHandler}
           disabled={isLoading ? true : false}
           type="submit"
         >
           {isLoading ? "Please wait..." : "Submit"}
         </button>
       </div>
        
        }
       
      </div>
    </Wrapper>
  );
};

export default TaskSubmission;
