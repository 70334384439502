import React, { useState, useEffect } from 'react';

function useIsMobile() {
   const [isDesktop, setIsDesktop] = useState(false);
   

   useEffect(() => {
      const checkDevice = () => {
        const userAgent = navigator.userAgent 
        const regex = /\b(Mobi|Android|iPhone|iPad|iPod)\b/;


        if (regex.test(userAgent)) {
          setIsDesktop(false);
        } else {
          setIsDesktop(true);
        }
      };
  
      checkDevice();
      window.addEventListener('resize', checkDevice);
  
      return () => {
        window.removeEventListener('resize', checkDevice);
      };
    }, []);


  return {isDesktop}
}

export default useIsMobile